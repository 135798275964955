<template>
  <div class="s-pannelum-wrapper">
    <el-row style="height: 100%">
      <el-col :span="isVrMode ? 12 : 24" style="height: 100%">
        <div id="pannellum-1" ref="mainPanel"></div>
      </el-col>
      <el-col :span="12" style="height: 100%" v-show="isVrMode">
        <div id="pannellum-2" style="height: 100%"></div>
      </el-col>
    </el-row>

    <div class="s-pannelum-actions">
      <el-button
        icon="el-icon-d-arrow-right"
        @click="next"
        type="primary"
        round
        v-if="!finished"
      >{{ this.disableSelection ? 'Даље' : 'Заврши' }}</el-button>
    </div>

    <el-button
      icon="el-icon-close"
      type="danger"
      class="panellum-exit-btn"
      title="Exit"
      circle
      @click="exit"
    ></el-button>

    <div class="s-pannelum-counter">
      <ul>
        <li>
          <span id="seconds">{{ points }}</span>
          Поена
        </li>
      </ul>
      <countdown
        :time="seconds * 1000"
        @countdownend="showResult"
        v-show="!disableSelection"
        v-if="panoramaLoaded"
      >
        <template slot-scope="props">
          <ul>
            <li>
              <span id="seconds">{{ props.totalSeconds }}</span>
              Секунди
            </li>
          </ul>
        </template>
      </countdown>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import Countdown from "@xkeshi/vue-countdown";
import { Notification } from "element-ui";
import cloneDeep from "lodash/cloneDeep";


import HotspotComponent from "./Hotspot.vue";

export default {
  name: "Pannellum",
  components: {
    Countdown,
  },
  props: {
    type: {
      type: String,
      default: "equirectangular",
      required: false
    },
    panorama: {
      type: String,
      default: "",
      required: true
    },
    hotspots: {
      type: Array,
      default: () => [],
      required: false
    },
    intro: {
      type: Object,
      default: null,
      required: false
    },
    seconds: {
      type: Number,
      required: true,
      default: 0
    },
    finished: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data() {
    return {
      pannellum: null,
      secondPannellum: null,
      panoramaLoaded: false,
      notifyMessage: null,
      selected: {},
      disableSelection: false,
      isVrMode: false,
      points: 0,
      totalPoints: 0,
      introModal: null
    };
  },
  watch: {
    panorama(value, oldValue) {
      if (value === oldValue) return;
      this.loadPanorama();
      this.displayIntro();
    },
    finished(value, oldValue) {
      if (value !== oldValue && value === true) {
        const percentage = ((this.points / this.totalPoints) * 100).toFixed(2);
        this.showNotification(
          "Резултат",
          `
            Остварио си ${this.points} поен(а) од укупно ${this.totalPoints}.
            Проценат успешности је ${percentage}%
            Увек можеш изнова започети авантуру уколико ниси задовољан резултатом.
          `
        );
      }
    }
  },
  methods: {
    displayIntro() {
      if (!this.intro) {
        return;
      }
      this.introModal = Notification.info({
        title: this.intro.title,
        message: this.intro.description,
        position: "top-center",
        type: "info",
        duration: 0,
        showClose: false,
        customClass: "intro-modal",
        onClick: () => {
          this.introModal.close();
        }
      });
    },
    closeIntro() {
      this.introModal && this.introModal.close();
    },
    showNotification(title, message, type = "success") {
      if (this.notifyMessage) this.notifyMessage.close();
      this.notifyMessage = Notification.success({
        title,
        message,
        position: "top-left",
        type,
        duration: 0
      });
    },
    loadPanorama() {
      if (this.pannellum) this.pannellum.destroy();
      if (this.secondPannellum) this.secondPannellum.destroy();

      const otherHotSpots = this.isVrMode ? cloneDeep(this.hotspots) : [];

      const hotSpots = this.hotspots.map((hotspot, index) => {
        const sharedState = new Vue({
          data: {
            ...hotspot,
            id: index,
            wronglySelectedImage: false,
            selected: null,
            selectedImage: "",
            activeTooltip: false
          }
        });
        const instance = new (Vue.extend(HotspotComponent))({
          data: sharedState._data,
          propsData: {
            pannellumData: this._data
          }
        });

        hotspot.id = index;
        hotspot.instance = instance;
        hotspot.createTooltipFunc = this.hotspotFnc.bind(this);

        if (this.isVrMode) {
          const secondInstance = new (Vue.extend(HotspotComponent))({
            data: sharedState._data,
            propsData: {
              pannellumData: this._data
            }
          });
          otherHotSpots[index].id = 1000000 + index;
          otherHotSpots[index].instance = secondInstance;
          otherHotSpots[index].createTooltipFunc = this.hotspotFnc.bind(this);
        }

        return hotspot;
      });

      this.pannellum = pannellum.viewer("pannellum-1", {
        hotSpotDebug: process.env.NODE_ENV === 'development',
        orientationOnByDefault: true,
        showFullscreenCtrl: false,
        autoLoad: true,
        mouseZoom: false,
        keyboardZoom: false,
        showZoomCtrl: false,
        maxLevel: 1,
        type: this.type,
        panorama: `${window.location.origin}/static/${this.panorama}`,
        hotSpots,
        ...(this.isVrMode
          ? {
              draggable: false,
              touchPanSpeedCoeffFactor: 0
            }
          : {})
      });

      this.pannellum.on("load", () => {
        this.panoramaLoaded = true;
      });

      if (this.isVrMode) {
        this.secondPannellum = pannellum.viewer("pannellum-2", {
          // hotSpotDebug: true,
          orientationOnByDefault: true,
          compass: false,
          showFullscreenCtrl: false,
          autoLoad: true,
          mouseZoom: false,
          keyboardZoom: false,
          showZoomCtrl: false,
          maxLevel: 1,
          type: this.type,
          panorama: `${window.location.origin}/static/${this.panorama}`,
          hotSpots: otherHotSpots,
          ...(this.isVrMode
            ? {
                draggable: false,
                touchPanSpeedCoeffFactor: 0
              }
            : {})
        });

        window.addEventListener(
          "deviceorientation",
          () => {
            if (!this.pannellum.isOrientationActive()) {
              this.pannellum.startOrientation();
            }
          },
          true
        );
      }
    },
    hotspotFnc(hotSpotDiv, hotspotArgs) {
      const mountedInstance = hotspotArgs.instance.$mount(
        document.createElement("div")
      );
      hotSpotDiv.appendChild(mountedInstance.$el);

      mountedInstance.setRandomHotspotAsBackground(hotspotArgs.initialMark);

      hotSpotDiv.onclick = () => {
        if (this.disableSelection) return;
        mountedInstance.setRandomHotspotAsBackground();
      };
    },
    showResult() {
      this.disableSelection = true;
      this.totalPoints += this.hotspots.reduce((acc, hotspot) => {
        return acc + (hotspot.points || 1);
      }, 0);
      this.points += this.hotspots
        .map(hotspot => {
          return hotspot.instance.isCorrectAnswer ? hotspot.points || 1 : 0;
        })
        .reduce((total, points) => total + points, 0);
    },
    next() {
      if (!this.disableSelection) {
        this.disableSelection = true;
        return this.showResult();
      }
      this.$emit("next", () => {
        this.disableSelection = false;
        this.panoramaLoaded = false;
        this.closeIntro();
      });
    },
    exit() {
      this.$router.push({
        name: "home"
      });
    }
  },
  mounted() {
    this.isVrMode = !!this.$route.query.vr;
    this.loadPanorama();
    this.displayIntro();
  },
  beforeDestroy() {
    this.closeIntro();
    if (this.pannellum) {
      this.pannellum.destroy();
    }

    if (this.notifyMessage) {
      this.notifyMessage.close();
    }
  }
};
</script>

<style lang="scss">
#panorama {
  width: 600px;
  height: 400px;
}

.s-pannelum-wrapper {
  display: contents;
}

.s-pannelum-actions {
  position: relative;
  z-index: 1000;
  position: absolute;
  bottom: 0.5rem;
  left: 0.5rem;
}

.panellum-exit-btn {
  position: absolute;
  top: 5px;
  right: 5px;
}

.points-section {
  top: 0;
  left: 0;
}

.s-pannelum-counter {
  color: #333;
  background: rgba($color: yellow, $alpha: 0.8);
  text-align: center;
  position: absolute;
  right: 0;
  bottom: 0;

  ul {
    padding: 0.5rem;
    margin-block-start: 0;
    margin-block-end: 0;

    @media screen and (max-width: 600px) {
      font-size: 0.2rem;
    }
  }

  li {
    display: inline-block;
    font-size: 0.7em;
    @media screen and (max-width: 600px) {
      font-size: 1rem;
    }
    list-style-type: none;
    text-transform: uppercase;
  }

  li span {
    display: block;
    font-size: 1.5rem;

    @media screen and (max-width: 600px) {
      font-size: 1rem;
    }
  }
}

.pnlm-orientation-button {
  display: none !important;
}

.intro-modal {
  background-color: rgba(58, 142, 230, 0.85) !important;

  .el-notification__title {
    color: #fff;
    font-weight: 700;
  }

  .el-notification__content {
    color: #fff;
  }

  .el-notification__icon {
    display: none;
  }
}
</style>
