<template>
    <div
      class="custom-tooltip"
      :style="{
        backgroundImage: selectedImage
      }"
      :class="{ 'active-tooltip': activeTooltip }"
      @mouseover="mouseOver"
      @mouseleave="mouseLeave"
    >
      <div class="result"
        v-if="pannellumData.disableSelection"
      >
        <img
          src="./../assets/yes.png"
          alt="Correct"
          class="yes-image"
          v-if="isCorrectAnswer"
        />

        <img
          src="./../assets/no.png"
          alt="Wrong"
          class="no-image"
          v-else
        />
      </div>
      <span
        ref="tooltipEl"
        :style="tooltipStyles()"
        class="tooltip-explanation"
      >
        <img
          v-if="pannellumData.disableSelection && !isCorrectAnswer"
          :src="reasonImageSrc"
          alt="reason"
          class="reason-img"
        />

        <p
          v-if="pannellumData.disableSelection && reason"
          class="reason"
        >
        {{ reason }}
        </p>
        {{ description }}
      </span>
    </div>
</template>

<script>
import random from 'lodash/random';

export default {
  props: {
    pannellumData: {
      type: Object
    }
  },
  computed: {
    reasonImageSrc() {
      return require(`./../assets/hotspots/${this.answer}.svg`);
    },
    isCorrectAnswer() {
      return this.selected === this.answer;
    }
  },
  methods: {
    tooltipStyles() {
      if (!this.$refs.tooltipEl) return {};

      return {
        bottom: this.$refs.tooltipEl.parentElement.offsetHeight + 12 + 'px',
        width: this.$refs.tooltipEl.scrollWidth - 20 + 'px',
        marginLeft: -(
          this.$refs.tooltipEl.scrollWidth -
          this.$refs.tooltipEl.parentElement.offsetWidth
        ) / 2 + 'px'
      }
    },
    getNewHotspotImageIndex() {
      let selectedHotspotIndex = this.options.indexOf(this.selected);
      let hotspotImageIndex = random(0, this.options.length - 1);
      if (selectedHotspotIndex === hotspotImageIndex) {
        return this.getNewHotspotImageIndex(this);
      }
      return hotspotImageIndex;
    },
    setRandomHotspotAsBackground(initialKey = null) {
      let hotspotImageIndex = this.getNewHotspotImageIndex();
      let hotspotKey = initialKey ? initialKey : this.options[hotspotImageIndex];
      import(`./../assets/hotspots/${hotspotKey}.svg`).then(v => {
        this.selectedImage = `url(${window.location.origin}${v.default})`;
      })
      this.selected = hotspotKey;
    },
    mouseOver() {
      this.activeTooltip = !this.activeTooltip;
    },
    mouseLeave() {
      this.activeTooltip = false;
    }
  },
  mounted() {
    import(`./../assets/hotspots/${this.initialMark}.svg`).then(v => {
        this.selectedImage = `url(${window.location.origin}${v.default})`;
    })
  }
}
</script>

<style lang="scss">

.custom-hotspot {
  height: 30px;
  width: 30px;
  cursor: pointer;
  background-repeat: no-repeat;
  background-size: contain;

  .custom-tooltip {
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    position: relative;

    .tooltip-explanation {
      line-height: 1rem;
      font-size: 12px;
    }
  }

  .result {
    position: absolute;
    top: -5px;
    right: -5px;
  }

  .reason {
    color: #cc3434;
  }

  .reason-img {
    height: 50px;
    width: 50px;
  }

  .yes-image, .no-image {
    width: 15px;
    height: 15px;
    display: inline;
  }

  @media screen and (min-width: 600px) {
    height: 50px;
    width: 50px;

    .yes-image, .no-image {
      width: 20px;
      height: 20px;
    }
  }

  @media screen and (min-width: 1024px) {
    height: 60px;
    width: 60px;

    .yes-image, .no-image {
      width: 30px;
      height: 30px;
    }
  }
}
div.custom-tooltip span {
    visibility: hidden;
    position: absolute;
    border-radius: 3px;
    background-color: #fff;
    color: #000;
    text-align: center;
    max-width: 200px;
    padding: 5px 10px;
    margin-left: -220px;
    cursor: default;

    > p {
      margin: 0;
    }
}

div.custom-tooltip {
  &.active-tooltip {
    span {
      visibility: visible;
    }
  }
}

div.custom-tooltip:hover span:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-width: 10px;
    border-style: solid;
    border-color: #fff transparent transparent transparent;
    bottom: -20px;
    left: -10px;
    margin: 0 50%;
}
</style>
