<template>
  <div class="s-guide">
    <pannellum
      :panorama="currentPanorama.image"
      :hotspots="currentHotspots"
      :intro="currentPanoramaIntro"
      :seconds="currentPanorama.seconds"
      :finished="finished"
      @next="nextPanorama"
    />
  </div>
</template>

<script>
import { groupBy, take, flatten, map } from "lodash-es";
import panoramas from "./../assets/data.json";
import Pannellum from "./../components/Pannellum.vue";
import { NUMBER_OF_PANORAMAS } from "./../constants";

export default {
  name: "app",
  components: {
    Pannellum,
  },
  data() {
    const panoramasSubsection = flatten(
      map(groupBy(panoramas, "group"), (groupPanoramas, key) =>
        take(
          groupPanoramas.sort(() => 0.5 - Math.random()),
          NUMBER_OF_PANORAMAS[key]
        )
      )
    );

    return {
      panoramas: panoramasSubsection,
      panoramaIndex: 0,
      finished: false,
    };
  },
  computed: {
    currentPanorama() {
      return this.panoramas[this.panoramaIndex];
    },
    currentHotspots() {
      return (this.panoramas[this.panoramaIndex]["hotspots"] || []).map(
        (hotspot) => {
          hotspot.createTooltipArgs = hotspot;
          return hotspot;
        }
      );
    },
    currentPanoramaIntro() {
      return this.currentPanorama ? this.currentPanorama.intro : null;
    },
  },
  methods: {
    nextPanorama(callback) {
      if (this.panoramaIndex >= this.panoramas.length - 1) {
        this.finished = true;
        return;
      }
      this.panoramaIndex++;
      callback();
    },
  },
};
</script>
